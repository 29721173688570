<template>
  <div>
    <div class="d-flex flex-nowrap pb-2 mb-2" style="overflow-y: auto">
      <div
        v-for="item in tabs"
        :key="item.status"
        style="min-width: 235px; width: 20%"
        class="ma-1"
      >
        <v-btn
          block
          :color="filters.status == item.status ? 'primary' : 'defalut'"
          @click="onTabChange(item.status)"
        >
          <img :src="item.img" alt="" style="width: 25px; margin-right: 15px" />
          {{ item.text }}
          <v-chip v-if="item.status == 1" class="ma-2" small>
            {{ counts.totalWaitingProcess }}
          </v-chip>
          <v-chip v-else-if="item.status == 2" class="ma-2" small>
            {{ counts.myProcessing }}/ {{ counts.totalProcessing }}
          </v-chip>
          <v-chip v-else-if="item.status == 3" class="ma-2" small>
            {{ counts.myWaitingConfirm }}/ {{ counts.totalWaitingConfirm }}
          </v-chip>
        </v-btn>
      </div>
    </div>

    <v-simple-table
      fixed-header
      height="calc(100vh - 216px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.code')"
                :placeholder="$t('labels.code')"
                name="code"
                sort-name="code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.date_create')"
                :placeholder="$t('labels.date_create')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="sortedDepartments"
                :label="$t('labels.department')"
                :placeholder="$t('labels.department')"
                name="department"
                sort-name="department"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="sortedTypes"
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="type"
                sort-name="type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.ssc')"
                :placeholder="$t('labels.ssc')"
                name="ssc_support"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectIdentityWarehouse
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="id_identity_create"
                sort-name="identity_create_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th role="columnheader">
              <SelectIdentityWarehouse :label="$t('labels.for_employee')" :placeholder="$t('labels.for_employee')"
                name="id_identity_receipt" sort-name="id_identity_receipt" :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th> -->
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="order_tracking_id"
                sort-name="order_tracking_id"
                :default-value="filters.order_tracking_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="stateOptions"
                :label="$t('labels.warehouse_state')"
                :placeholder="$t('labels.warehouse_state')"
                name="order_state"
                sort-name="order_state"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="order_id_deliery_company"
                sort-name="delivery_short_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.delivery_order_id')"
                :placeholder="$t('labels.delivery_order_id')"
                name="order_delivery_id"
                sort-name="order_delivery_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.basket')"
                :placeholder="$t('labels.basket')"
                name="basket_code"
                sort-name="basket_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th role="columnheader">
              <InputFilter :label="$t('labels.position')" :placeholder="$t('labels.position')" name="cell_id"
                sort-name="cell_id" :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th> -->
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.quantity_1')"
                :placeholder="$t('labels.quantity_1')"
                name="quantity"
                sort-name="quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.money')"
                :placeholder="$t('labels.money')"
                name="money"
                sort-name="money"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th role="columnheader">
              <InputFilter :label="$t('labels.receipt_tracking')" :placeholder="$t('labels.receipt_tracking')"
                name="receipt_tracking_id" sort-name="receipt_tracking_id" :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th> -->
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.working_date')"
                :placeholder="$t('labels.working_date')"
                name="working_date"
                sort-name="working_date"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="workingShifts"
                :label="$t('labels.shift')"
                :placeholder="$t('labels.shift')"
                name="working_shift"
                sort-name="working_shift"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.e_market')"
                :placeholder="$t('labels.e_market')"
                name="e_market_name"
                sort-name="e_market_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.shop')"
                :placeholder="$t('labels.shop')"
                name="shop_name"
                sort-name="shop_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.customer_order_id')"
                :placeholder="$t('labels.customer_order_id')"
                name="e_market_order_id"
                sort-name="e_market_order_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.e_market_item_id')"
                :placeholder="$t('labels.e_market_item_id')"
                name="e_market_item_id"
                sort-name="e_market_item_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.e_market_sku_id')"
                :placeholder="$t('labels.e_market_sku_id')"
                name="e_market_sku_id"
                sort-name="e_market_sku_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                v-if="
                  config &&
                  config.STATUS &&
                  [
                    config.STATUS.PROCESSING,
                    config.STATUS.WAITING_CONFIRM,
                  ].includes(filters.status)
                "
                :options="yesNoOptions"
                :label="$t('labels.join')"
                :placeholder="$t('labels.join')"
                name="join_status"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center">
              <span
                class="text-decoration-underline cursor-pointer primary--text"
                @click="showDialogDetail(item)"
              >
                {{ item.code }}
              </span>
            </td>
            <td class="text-center">{{ formatDateTime(item.created_at) }}</td>
            <td class="text-capitalize text-center">
              {{
                (item.department &&
                  config.DEPARTMENT_TEXT &&
                  config.DEPARTMENT_TEXT[item.department]) ||
                ""
              }}
            </td>
            <td class="text-capitalize text-center">
              {{
                (item.type &&
                  config.TYPE_TEXT &&
                  config.TYPE_TEXT[item.type]) ||
                ""
              }}
            </td>
            <td class="text-center">{{ item.ssc_support ? "Yes" : "No" }}</td>
            <td class="text-center">{{ item.warehouse_code }}</td>
            <td class="text-center">{{ item.identity_create_name }}</td>
            <td class="text-center">
              <OrderTracking
                v-if="item.order_tracking_id"
                :tracking-id="item.order_tracking_id"
              />
              <div
                v-if="item.order_tracking_id && item.order_request_cancel"
                class="mt-1"
              >
                <v-chip color="error" small>{{
                  $t("labels.cancelled")
                }}</v-chip>
              </div>
            </td>
            <td class="text-center">
              <span
                v-if="item.order_tracking_id && item.order_state !== null"
                >{{ $t(`labels.order_status_${item.order_state}`) }}</span
              >
            </td>
            <td class="text-center">
              {{ item.delivery_short_name || "" }}
            </td>
            <td class="text-center">
              <DeliveryTracking
                v-if="item.order_tracking_id && item.delivery_order_id"
                :order="item"
              />
            </td>
            <td class="text-center">{{ item.basket_code || "" }}</td>
            <td class="text-center">
              <SkuDownload v-if="item.sku" :item="item" />
            </td>
            <td class="text-center">{{ item.quantity || "" }}</td>
            <td class="text-center">
              {{ (item.money && formatNumber(item.money)) || "" }}
            </td>
            <!-- <td class="text-center">{{ item.receipt_tracking_id || '' }}</td> -->
            <td class="text-center">
              {{
                (item.working_date &&
                  formatDateTime(item.working_date, "DD/MM/YYYY")) ||
                ""
              }}
            </td>
            <td class="text-center">
              {{
                (item.working_shift &&
                  config &&
                  config.WORKING_SHIFT_TEXT &&
                  config.WORKING_SHIFT_TEXT[item.working_shift]) ||
                ""
              }}
            </td>
            <td class="text-center">{{ item.e_market_name }}</td>
            <td class="text-center">{{ item.shop_name }}</td>
            <td class="text-center">{{ item.e_market_order_id }}</td>
            <td class="text-center">
              <TextCopy
                v-if="item.e_market_item_id"
                :text="item.e_market_item_id"
                class="primary--text"
              />
            </td>
            <td class="text-center">
              <TextCopy
                v-if="item.e_market_sku_id"
                :text="item.e_market_sku_id"
                class="error--text"
              />
            </td>
            <td>
              <div
                class="text-center"
                v-if="
                  config &&
                  config.STATUS &&
                  ![config.STATUS.CANCEL].includes(item.status) &&
                  checkPermission(['internal_request_process'])
                "
              >
                <v-btn
                  :title="$t('labels.join')"
                  color="primary"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    [
                      config.STATUS.WAITING_PROCESS,
                      config.STATUS.PROCESSING,
                      config.STATUS.WAITING_CONFIRM,
                    ].includes(item.status) &&
                    (!item.request_member_ids ||
                      !item.request_member_ids.includes(`Ω${me.identity_id}Ω`))
                  "
                  @click="joinRequest(item.id)"
                >
                  {{ $t("labels.join") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.reopen')"
                  color="primary"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.status === config.STATUS.DONE &&
                    config.STATUS_MAPS[item.status].includes(
                      config.STATUS.PROCESSING
                    )
                  "
                  @click="updateStatus(item, config.STATUS.PROCESSING)"
                >
                  {{ $t("labels.reopen") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.wait_confirm')"
                  color="info"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.request_member_ids &&
                    item.request_member_ids.includes(`Ω${me.identity_id}Ω`) &&
                    config.STATUS_MAPS[item.status].includes(
                      config.STATUS.WAITING_CONFIRM
                    )
                  "
                  @click="updateStatus(item, config.STATUS.WAITING_CONFIRM)"
                >
                  {{ $t("labels.wait_confirm") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.finish')"
                  color="success"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.request_member_ids &&
                    item.request_member_ids.includes(`Ω${me.identity_id}Ω`) &&
                    config.STATUS_MAPS[item.status].includes(config.STATUS.DONE)
                  "
                  @click="updateStatus(item, config.STATUS.DONE)"
                >
                  {{ $t("labels.finish") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.cancel_process')"
                  color="error"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.request_member_ids &&
                    item.request_member_ids.includes(`Ω${me.identity_id}Ω`) &&
                    config.STATUS_MAPS[item.status].includes(
                      config.STATUS.CANCEL
                    )
                  "
                  @click="updateStatus(item, config.STATUS.CANCEL)"
                >
                  {{ $t("labels.cancel_process") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.exit')"
                  color="warning"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.request_member_ids &&
                    item.request_member_ids.includes(`Ω${me.identity_id}Ω`)
                  "
                  @click="exitProcess(item.id)"
                >
                  {{ $t("labels.exit") }}
                </v-btn>

                <v-btn
                  :title="$t('labels.add_employee')"
                  color="purple"
                  class="mb-1"
                  outlined
                  small
                  block
                  v-if="
                    item.request_member_ids &&
                    item.request_member_ids.includes(`Ω${me.identity_id}Ω`)
                  "
                  @click="showPopupAddEmployee(item.id)"
                >
                  {{ $t("labels.add_employee") }}
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="2">
        <v-btn color="success" @click="reportExport" class="mr-2">
          <v-icon>mdi-download</v-icon> Excel
        </v-btn>
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          v-if="
            (counts.countByType && counts.countByType.length > 0) ||
            (counts.countByDepartment && counts.countByDepartment.length > 0)
          "
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on"
              >mdi-dots-vertical</v-icon
            >
          </template>

          <v-list min-width="260px" class="px-2">
            <table style="font-size: 12px; width: 100%">
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    class="text-center error--text font-weight-medium fs-14"
                  >
                    {{ $t("labels.classify") }}
                  </td>
                </tr>
                <tr
                  v-for="(item, i) in counts.countByType"
                  :key="`cbt_${i}`"
                  class="text-right"
                >
                  <td class="pa-1">
                    {{
                      (item.type &&
                        config.TYPE_TEXT &&
                        config.TYPE_TEXT[item.type]) ||
                      ""
                    }}
                  </td>
                  <td style="min-width: 80px" class="pa-1">
                    <b class="blue--text">
                      {{ formatNumber(item.count_id) }}
                    </b>
                    {{ $t("labels.request") }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    class="text-center error--text font-weight-medium fs-14 pt-3"
                  >
                    {{ $t("labels.department") }}
                  </td>
                </tr>
                <tr
                  v-for="(item, i) in counts.countByDepartment"
                  :key="`cbd_${i}`"
                  class="text-right"
                >
                  <td class="pa-1">
                    {{
                      (item.department &&
                        config.DEPARTMENT_TEXT &&
                        config.DEPARTMENT_TEXT[item.department]) ||
                      ""
                    }}
                  </td>
                  <td class="pa-1">
                    <b class="blue--text">{{ formatNumber(item.count_id) }}</b>
                    {{ $t("labels.request") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="8">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="2"> </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        dark
        fixed
        bottom
        right
        fab
        color="primary"
        @click="showDialogRequest"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog
      v-model="dialogRequest"
      persistent
      :max-width="createType == 25 ? '1024px' : '600px'"
    >
      <CreatedInternalRequest
        v-if="dialogRequest"
        :types="sortedTypes"
        :departments="sortedDepartments"
        :working-shifts="workingShifts"
        :config="config"
        @typeChange="createTypeChange"
        @closeDialog="closeDialogRequest"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogAddEmployee" persistent max-width="600px">
      <AddEmployeeInternalRequest
        v-if="dialogAddEmployee"
        :id-internal-request="id_processing"
        @closeDialog="hidePopupAddEmployee"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogDetail" max-width="600px">
      <DetailInternalRequest
        v-if="dialogDetail"
        :item="itemDetail"
        :config="config"
        @closeDialog="closeDialogDetail"
      />
    </v-dialog>

    <v-dialog v-model="dialogBeforeDone" persistent max-width="400px">
      <BeforeConfirmInternalRequest
        v-if="dialogBeforeDone"
        :item="itemDetail"
        :config="config"
        @closeDialog="hideDialogBeforeDone"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  debounce,
  formatDateTime,
  formatNumber,
  downloadExcelFile,
  getUrlParameter,
} from "@/libs/helpers";
import { GOODS_ISSUE_STATE, YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "Index",
  components: {
    // RequestMessages: () => import('@/components/internal_requests/Messages'),
    // RequestFiles: () => import('@/components/internal_requests/Files'),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    SelectIdentityWarehouse: () =>
      import("@/components/table/SelectIdentityWarehouse"),
    CreatedInternalRequest: () =>
      import("@/components/internal_requests/Create"),
    AddEmployeeInternalRequest: () =>
      import("@/components/internal_requests/AddEmployee"),
    DetailInternalRequest: () =>
      import("@/components/internal_requests/Detail"),
    BeforeConfirmInternalRequest: () =>
      import("@/components/internal_requests/BeforeConfirm"),
    SkuDownload: () => import("@/components/goods/SkuDownload"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    DeliveryTracking: () => import("@/components/common/DeliveryTracking"),
    TextCopy: () => import("@/components/common/TextCopy"),
  },
  data: () => ({
    tabs: [],
    tab: 0,
    page: 1,
    totalPage: 1,
    counts: {},
    items: [],
    config: {},
    filters: {
      status: 1,
    },
    id_processing: null,
    employee_options: [],

    isLoading: false,
    dialogRequest: false,
    dialogAddEmployee: false,
    dialogDetail: false,
    dialogBeforeDone: false,
    itemDetail: {},
    stateOptions: [...GOODS_ISSUE_STATE],
    yesNoOptions: [...YES_NO_OPTIONS],
    createType: null,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    me() {
      return window.me;
    },
    joinOptions() {
      return [
        {
          value:
            (this.config &&
              this.config["JOIN_STATUS"] &&
              this.config["JOIN_STATUS"]["HAS_ME"]) ||
            "has_me",
          text: `Có`,
        },
        {
          value:
            (this.config &&
              this.config["JOIN_STATUS"] &&
              this.config["JOIN_STATUS"]["NOT_ME"]) ||
            "not_me",
          text: `Không`,
        },
      ];
    },
    sortedTypes() {
      if (!this.config || !this.config.TYPE) {
        return [];
      }
      const types = [];
      const configTypes = Object.values(this.config.TYPE).filter(
        (key) => key != 26
      );
      configTypes.forEach((t) => {
        types.push({
          value: t,
          text: this.config.TYPE_TEXT[t],
        });
      });
      function compare(a, b) {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      }
      return types.sort(compare);
    },
    sortedDepartments() {
      if (!this.config || !this.config.DEPARTMENT) {
        return [];
      }
      const departments = [];
      const configDepartments = Object.values(this.config.DEPARTMENT);
      configDepartments.forEach((t) => {
        departments.push({
          value: t,
          text: this.config.DEPARTMENT_TEXT[t],
        });
      });

      function compare(a, b) {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      }
      return departments.sort(compare);
    },
    workingShifts() {
      const workingShifts = [];
      if (!this.config || !this.config.WORKING_SHIFT) {
        return [];
      }
      const configWorkingShifts = Object.values(this.config.WORKING_SHIFT);
      configWorkingShifts.forEach((w) => {
        workingShifts.push({
          value: w,
          text: this.config.WORKING_SHIFT_TEXT[w],
        });
      });
      return workingShifts;
    },
  },
  methods: {
    formatDateTime,
    formatNumber,
    downloadExcelFile,
    getUrlParameter,
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getConfig() {
      const { data } = await httpClient.post("/internal-request/v1/get-config");
      this.config = { ...data };
    },

    getList: debounce(function () {
      httpClient
        .post("/internal-request/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.counts = { ...data.counts };
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    showDialogRequest() {
      this.createType = null;
      this.dialogRequest = true;
    },
    closeDialogRequest() {
      this.createType = null;
      this.dialogRequest = false;
    },
    showDialogDetail(item) {
      this.itemDetail = { ...item };
      this.dialogDetail = true;
    },
    closeDialogDetail() {
      this.itemDetail = {};
      this.dialogDetail = false;
    },
    createTypeChange(type) {
      this.createType = type;
    },
    showDialogBeforeDone(item) {
      this.itemDetail = { ...item };
      this.dialogBeforeDone = true;
    },
    hideDialogBeforeDone() {
      this.itemDetail = {};
      this.dialogBeforeDone = false;
    },
    async updateStatus(item, status) {
      if (
        status == this.config.STATUS.DONE &&
        [this.config.TYPE.OVERTIME_TIMEKEEPING].includes(item.type)
      ) {
        this.showDialogBeforeDone(item);
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/internal-request/v1/change-status", {
          id_internal_request: item.id,
          status,
        });
        await this.getList();
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },
    async joinRequest(id_internal_request) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/internal-request/v1/join", {
          id_internal_request,
        });
        await this.getList();
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },
    async exitProcess(id_internal_request) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/internal-request/v1/exit", {
          id_internal_request,
        });
        await this.getList();
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },
    showPopupAddEmployee(id_internal_request) {
      this.id_processing = id_internal_request;
      this.dialogAddEmployee = true;
    },
    hidePopupAddEmployee() {
      this.id_processing = null;
      this.dialogAddEmployee = false;
    },
    onTabChange(tab) {
      this.filters = { ...this.filters, status: tab };
    },

    listenSocket() {
      window.socket.on("internal_request_status_changed", (data) => {
        if ([data.newStatus, data.oldStatus].includes(this.filters.status)) {
          this.getList();
        }
      });
      window.socket.on("internal_request_new_message", (data) => {
        this.$root.$emit("internalRequestListenSocket", data);
      });
    },
    async reportExport() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = `xu-ly-noi-bo.xlsx`;

      try {
        await this.downloadExcelFile(
          "/internal-request/v1/report-export",
          this.filters,
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.tabs = [
      {
        text: this.$t("labels.pending"),
        status: 1,
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
      },
      {
        text: this.$t("labels.processing"),
        status: 2,
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
      },
      {
        text: this.$t("labels.wait_confirm"),
        status: 3,
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
      },
      {
        text: this.$t("labels.done"),
        status: 4,
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
      {
        text: this.$t("labels.stopped"),
        status: 5,
        img: require("@/assets/internal_requests/5_ngung_xu_ly.png"),
      },
    ];
  },
  async mounted() {
    const filters = { ...this.filters };
    const order_tracking_id = this.getUrlParameter("order_tracking_id");
    if (order_tracking_id) {
      filters.order_tracking_id = order_tracking_id;
    }
    const status = this.getUrlParameter("status");
    if (status) {
      filters.status = status;
      this.tab = status - 1;
    }
    this.filters = { ...filters };
    await this.getConfig();
    await this.getList();
    this.listenSocket();
  },
};
</script>

<style scoped></style>
