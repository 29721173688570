<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" class="text-center">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" class="text-center">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              v-if="checkPermission(['goods_issue_manager_export_handover'])"
            >
              <SelectFilter
                :options="sourceOptions"
                :label="$t('labels.source')"
                :placeholder="$t('labels.source')"
                name="id_e_market"
                sort-name="id_e_market"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.shop')"
                :placeholder="$t('labels.shop')"
                name="customer_e_market_shop_name"
                sort-name="customer_e_market_shop_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th
              role="columnheader"
              class="text-center"
              v-if="checkPermission(['goods_issue_manager_export_handover'])"
            >
              <InputFilter
                :label="$t('labels.other_source')"
                :placeholder="$t('labels.other_source')"
                name="source_name"
                sort-name="source_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" class="text-center">
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="tracking_id"
                sort-name="tracking_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.customer_order_id')"
                :placeholder="$t('labels.customer_order_id')"
                name="customer_order_id"
                sort-name="customer_order_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.delivery_order_id')"
                :placeholder="$t('labels.delivery_order_id')"
                name="delivery_order_id"
                sort-name="delivery_order_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="create_time"
                sort-name="create_time"
                :sorting="filters.sort_by"
                :default-date="defaultToday()"
                :allow-null="false"
                :clearable="false"
                :max-range="maxRange"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="stateOptions"
                :label="$t('labels.warehouse_state')"
                :placeholder="$t('labels.warehouse_state')"
                name="state"
                sort-name="goods_issue_state_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.delivery_partner_state')"
                :placeholder="$t('labels.delivery_partner_state')"
                name="delivery_partner_state"
                sort-name="delivery_partner_state"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="deliveryStateOption"
                :label="$t('labels.delivery_state')"
                :placeholder="$t('labels.delivery_state')"
                name="id_delivery_state"
                sort-name="delivery_state_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template
              v-if="checkPermission(['goods_issue_manager_export_handover'])"
            >
              <th role="columnheader" class="text-center">
                <InputFilter
                  :label="$t('labels.export')"
                  :placeholder="$t('labels.export')"
                  name="barcode"
                  sort-name="barcode"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <DateRangeFilter
                  :label="$t('labels.handover_at')"
                  :placeholder="$t('labels.handover_at')"
                  name="handover_at"
                  sort-name="handover_at"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <!-- <td>{{ item.warehouse_code }}</td> -->
            <td>{{ item.company_name }}</td>
            <td v-if="checkPermission(['goods_issue_manager_export_handover'])">
              {{ item.e_marketplace_name }}
            </td>
            <td>{{ item.customer_e_market_shop_name }}</td>
            <!-- <td v-if="checkPermission(['goods_issue_manager_export_handover'])">
              {{ item.source_name }}
            </td> -->
            <td>{{ item.delivery_company }}</td>
            <td><OrderTracking :tracking-id="item.tracking_id" /></td>
            <td>{{ item.customer_order_id }}</td>
            <td>
              <a
                v-if="item.delivery_tracking_link"
                :href="urlTrackingDelivery(item)"
                target="_blank"
                >{{ item.delivery_order_id }}</a
              >
              <span v-else>{{ item.delivery_order_id }}</span>
            </td>
            <td>{{ formatDateTime(item.create_time) }}</td>
            <td>{{ $t(`labels.order_status_${item.state}`) }}</td>
            <td>{{ item.delivery_partner_state }}</td>
            <td>
              {{ $t(`labels.delivery_status_${item.id_delivery_state}`) }}
            </td>
            <template
              v-if="checkPermission(['goods_issue_manager_export_handover'])"
            >
              <td>
                <div v-html="item.item_html"></div>
              </td>
              <td>
                {{ item.hand_over_at ? formatDateTime(item.hand_over_at) : "" }}
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3">
          <div class="mr-2">
            {{ $t("labels.order") }}: <b>{{ formatNumber(sum.sumOrder) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="3">
        <div class="d-flex align-center">
          <v-btn
            color="success"
            @click="exportExcel"
            v-if="checkPermission(['goods_issue_excel'])"
            style="height: 40px"
            class="mr-2"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("labels.excel") }}
          </v-btn>
          <v-text-field
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadExcelFile,
  formatNumber,
} from "@/libs/helpers";
import {
  GOODS_ISSUE_STATE,
  DELIVERY_STATE,
  EXPORT_LIST_SOURCES,
  MAX_SELECT_RANGE,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  components: {
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {
      create_time: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    sum: {},
    stateOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
    sourceOptions: [...EXPORT_LIST_SOURCES],
    maxRange: MAX_SELECT_RANGE,
    isLoading: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    downloadExcelFile,
    defaultToday() {
      return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-issue-detail/v1/list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((row) => {
            const itemHtml = [];
            const splItems = (row.items && row.items.split(";")) || [];

            for (let i = 0; i < splItems.length; i++) {
              const jtems = splItems[i];
              const [
                id_goods,
                sku,
                customer_goods_barcode,
                quantity,
                soft_delete,
              ] = jtems.split("Ω");
              console.log(id_goods, sku);
              const itemClass = [];
              if (soft_delete == 1) {
                itemClass.push("text-decoration-line-through");
              }
              itemHtml.push(
                `<div class=${itemClass.join(
                  " "
                )}>${customer_goods_barcode} x ${quantity}</div>`
              );
            }

            row.item_html = itemHtml.join("");

            return row;
          });

          this.getCount();
        });
    }, 500),
    getCount() {
      httpClient
        .post("/goods-issue-detail/v1/count", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.sum = { ...data };
        });
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = "xuat-kho.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-issue-detail/v1/list-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    urlTrackingDelivery(item) {
      let tracking_link = item.delivery_tracking_link || "";
      if (tracking_link && tracking_link.includes("[delivery_order_id]")) {
        return tracking_link.replace(
          "[delivery_order_id]",
          item.delivery_order_id
        );
      } else {
        return `${tracking_link + item.delivery_order_id}`;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
