<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="code"
          :label="placeholder"
          class="c-input-small"
          dense
          outlined
          clearable
          hide-details
          autofocus
          @keyup.enter="inputScan"
          append-icon="mdi-qrcode-scan"
          @click:append="showQRCodeScan('code')"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" block @click="pickupLater()">
          {{ $t("labels.skip_location") }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="purple"
          block
          class="white--text"
          @click="pickupLaterRackAndBff()"
        >
          {{ $t("labels.skip_aisle") }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-if="currentPickingItem && currentPickingItem.goods_sku"
      >
        <v-alert color="error" text dark class="text-h2 mb-0">
          {{ currentPickingItem.cell_id }}
          <div
            v-if="goods.comment_pickup"
            style="font-size: 15px; line-height: 19px"
            class="font-weight-medium font-italic black--text"
          >
            {{ goods.comment_pickup }}
          </div>
        </v-alert>
        <v-alert color="green" text dark class="text-h2 box-quantity mb-0">
          <div class="sum-by-cell error--text">{{ sumByCell }}</div>
          {{ pickQuantity }}
        </v-alert>
        <v-alert color="secondary" text dark class="text-h4">
          <span class="text-uppercase">{{ $t("labels.basket") }}: </span>
          {{ currentPickingItem.basket_index }} [{{
            currentPickingItem.basket_code
          }}]
        </v-alert>
        <!--<div class="pt-2" v-if="prevPickingItem && prevPickingItem.id">
          {{ $t('labels.previous_command') }}: {{ $t('labels.basket') }}
          {{ prevPickingItem.basket_index }}: {{ prevPickingItem.sku }} x {{ prevPickingItem.quantity }} - {{ prevPickingItem.cell_id }}
        </div>-->
        <v-row>
          <v-col v-if="selected.process_type === 1" cols="6">
            <v-btn block color="error" @click="cancelPickupType1">
              {{ $t("labels.stop_pickup") }}
            </v-btn>
          </v-col>
          <v-col :cols="selected.process_type === 1 ? 6 : 12">
            <v-btn
              block
              color="warning"
              v-if="isShowRollCell"
              @click="showDialogOutOfStock"
            >
              {{ $t("labels.out_of_stock") }}
            </v-btn>
            <v-btn
              block
              color="purple white--text"
              v-else
              @click="showDialogMissingStock"
            >
              {{ $t("labels.missing_quantity_pickup") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else-if="!isLoading" class="text-center text-h6 error--text">
        {{ $t("messages.error_pickup_no_position") }}
      </v-col>

      <v-col cols="12">
        <div class="pb-2 font-weight-medium text-center fs-13">
          {{ $t("labels.sku") }}:
          {{ hideString(currentPickingItem.goods_sku, 2) }} -
          {{ $t("labels.barcode") }}:
          {{ hideString(currentPickingItem.goods_barcode, 3) }}
        </div>
        <div class="text-center fs-14">{{ goods.name }}</div>
        <div class="d-flex align-center justify-center" v-if="goods.url_images">
          <ImageViewer width="300px" height="auto" :url="goods.url_images" />
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="prevPickingItem && prevPickingItem.id_basket"
        class="text-center"
      >
        {{ $t("labels.previous_command") }}:
        <span class="error--text">
          {{ prevPickingItem.picked }} &times; {{ prevPickingItem.goods_sku }} -
          {{ prevPickingItem.goods_barcode }}
        </span>
        - {{ $t("labels.basket") }}:
        <span class="error--text">{{ prevPickingItem.basket_index }}</span>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogOutOfStock" persistent max-width="400px">
      <PickupOutOfStock
        v-if="dialogOutOfStock"
        :item="currentPickingItem"
        :goods="goods"
        @cancel="hideDialogOutOfStock"
        @confirm="confirmOutOfStock"
      />
    </v-dialog>
    <v-dialog v-model="dialogMissingStock" persistent max-width="400px">
      <PickupMissingStock
        v-if="dialogMissingStock"
        :item="currentPickingItem"
        :goods="goods"
        @cancel="hideDialogMissingStock"
        @confirm="confirmMissingStock"
      />
    </v-dialog>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import _ from "lodash";
import { BARCODE_PICKUP_LATER } from "@/libs/const";
import { hideString } from "@/libs/helpers";

export default {
  name: "PickupStep4",
  components: {
    PickupOutOfStock: () => import("@/components/goods_issue/PickupOutOfStock"),
    PickupMissingStock: () =>
      import("@/components/goods_issue/PickupMissingStock"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detailCommand: [],
    isLoading: false,
    code: null,
    items: [],
    currentPickingItem: {},
    prevPickingItem: {},
    currentPickingItemIndex: null,
    step: 1,
    dialogOutOfStock: false,
    dialogMissingStock: false,
    goods: {},
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    isShowRollCell() {
      if (!this.currentPickingItem) {
        return false;
      }
      const sameGoods = this.items.filter(
        (i) =>
          i.id_goods === this.currentPickingItem.id_goods &&
          i.cell_id !== this.currentPickingItem.cell_id &&
          !i.status &&
          this.currentPickingItem.goods_expired_date === i.goods_expired_date
      );
      return sameGoods.length > 0;
    },
    /* prevPickingItem() {
      if (!this.currentPickingItemIndex || this.currentPickingItemIndex < 1) {
        return {}
      }
      return this.items[this.currentPickingItemIndex - 1]
    }, */
    placeholder() {
      return this.$t("labels.qr_sku_sub_barcode");
      /*switch (this.step) {
        case 1:
          return 'Quét mã sọt'
        case 2:
          return 'Quét SKU/Barcode'
        case 3:
          return 'Quét vị trí'
        default:
          return ''
      }*/
    },
    pickQuantity() {
      const sub1 =
        +this.currentPickingItem.quantity - +this.currentPickingItem.picked;
      const sub2 =
        this.currentPickingItem.basket_cell_quantity -
        this.currentPickingItem.basket_cell_picked;
      return sub2 > sub1 ? sub1 : sub2;
    },
    sumByCell() {
      let sum1 = 0;
      let sum2 = 0;
      const checkedItems = [...this.items].filter(
        (c) =>
          c.id_goods === this.currentPickingItem.id_goods &&
          c.id_warehouse_location ===
            this.currentPickingItem.id_warehouse_location &&
          !c.status
      );
      checkedItems.forEach((i) => {
        sum1 += +i.quantity - +i.picked;
        sum2 += i.basket_cell_quantity - i.basket_cell_picked;
      });
      return sum2 > sum1 ? sum1 : sum2;
    },
    currentGoodsId() {
      return (
        (this.currentPickingItem && this.currentPickingItem.id_goods) || null
      );
    },
  },
  watch: {
    currentGoodsId() {
      this.getGoodsData();
    },
  },
  methods: {
    hideString,
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.inputScan();
    },
    async inputScan() {
      if (this.code && this.code.toUpperCase() === BARCODE_PICKUP_LATER) {
        this.code = null;
        await this.pickupLater();
        return false;
      }

      let inputCode = this.code || "";
      if (inputCode.includes("@")) {
        const inputCodeSpl = inputCode.split("@");
        inputCode = inputCodeSpl[0];
      }
      inputCode = inputCode.toUpperCase();

      const goodsS = ["" + this.currentPickingItem.goods_sku];
      /*if (this.currentPickingItem.goods_barcode) {
        goodsS.push(this.currentPickingItem.goods_barcode.toUpperCase())
      }*/
      if (this.currentPickingItem.goods_sub_barcode) {
        goodsS.push(this.currentPickingItem.goods_sub_barcode.toUpperCase());
      }

      if (this.goods.is_allow_pick_by_location) {
        goodsS.push(this.currentPickingItem.cell_id)
      }

      if (
        !this.currentPickingItem.is_out_of_stock &&
        !this.currentPickingItem.is_missing_stock &&
        !goodsS.includes(inputCode)
      ) {
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(
          this.$t("messages.wrong_code", { code: this.code })
        );
        this.code = null;
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-picked",
          {
            id_pickup_command: this.currentPickingItem.id_pickup_command,
            id_goods: this.currentPickingItem.id_goods,
            goods_sku: this.currentPickingItem.goods_sku,
            id_basket: this.currentPickingItem.id_basket,
            basket_code: this.currentPickingItem.basket_code,
            basket_index: this.currentPickingItem.basket_index,
            id_warehouse_location:
              this.currentPickingItem.id_warehouse_location,
            cell_id: this.currentPickingItem.cell_id,
            picked:
              this.currentPickingItem.out_of_stock_quantity ||
              this.currentPickingItem.out_of_stock_quantity === 0
                ? this.currentPickingItem.out_of_stock_quantity
                : this.pickQuantity,
            is_out_of_stock: this.currentPickingItem.is_out_of_stock,
            missing_quantity: this.currentPickingItem.missing_quantity || 0,
            goods_expired_date: this.currentPickingItem.goods_expired_date,
            turn: this.currentPickingItem.turn,
          }
        );
        if (!data || data.length === 0) {
          this.finishCommand();
          return false;
        }
        this.detailCommand = [...data];
        this.isLoading = false;
        this.code = null;
        this.prevPickingItem = {
          ...this.currentPickingItem,
          picked: this.pickQuantity,
        };
        this.sortLocations();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    finishCommand() {
      document.getElementById("PickupXong_player").play();
      this.$vToastify.success(this.$t("messages.pickup_done"));
      this.$emit("onUpdate", {
        ...this.filters,
        process_type: null,
        pickup_type: null,
        order_ids: [],
        scannedOrders: [],
        id_command: null,
        last_picked: {
          ...this.currentPickingItem,
          picked: this.pickQuantity,
          goods_name: this.goods.name,
        },
      });
    },

    async getDetailCommand() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-get-detail-command",
          {
            id_pickup_command: this.selected.id_command,
          }
        );
        this.detailCommand = [...data];
        this.isLoading = false;
        this.sortLocations();
      } catch (e) {
        this.isLoading = false;
      }
    },

    getZoneToNumber(zone) {
      return zone ? zone.charCodeAt(0) * 1000 : 0;
    },

    sortLocations() {
      let locations = [...this.detailCommand];
      const turn = (locations[0] && locations[0].turn) || 0;
      const isEvenTurn = turn % 2 === 0;

      // 0. Lấy tất cả vị trí có liên kết
      let tempPositions = _.chain(locations)
        .map((l) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = zone + parseInt(l.position);
          const bff = l.bff ? zone + parseInt(l.bff) : position;
          return [position, bff];
          /* if (!l.bff) {
          return [ parseInt(l.position), parseInt(l.position) ]
        }
        return [ parseInt(l.position), parseInt(l.bff) ] */
        })
        .uniq()
        .value();
      let positions = [];
      _.each(tempPositions, (tp) => {
        let pExisted = _.find(positions, (p) => {
          return (
            (p[0] === tp[0] && p[1] === tp[1]) ||
            (p[0] === tp[1] && p[1] === tp[0])
          );
        });
        if (!pExisted) {
          positions.push(tp);
        }
      });

      // 1. Chuyển tất cả vị trí có liên kết dãy về 1 dãy ảo
      let virtualPositions = [];
      _.each(locations, (l) => {
        let bffPosition = _.find(positions, (p) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = zone + parseInt(l.position);
          return position === p[0] || position === p[1];
          // return parseInt(l.position) === p[0] || parseInt(l.position) === p[1]
        });
        l.virtualPosition = Math.min(bffPosition[0], bffPosition[1]);
        if (virtualPositions.indexOf(l.virtualPosition) < 0) {
          virtualPositions.push(l.virtualPosition);
        }
      });

      // 2. Nhóm theo 1 dãy mới
      let locationGroups = _.groupBy(locations, "virtualPosition");
      virtualPositions.sort((a, b) => {
        return isEvenTurn ? a - b : b - a;
      });

      // 3. Sort từng dãy
      let orderAsc = isEvenTurn;
      let result = [];
      for (let vp of virtualPositions) {
        let vpLocations = locationGroups[`${vp}`];
        let newVpLocations = _.orderBy(
          vpLocations,
          ["indexing", "quantity"],
          [orderAsc ? "asc" : "desc", "asc"]
        );
        _.each(newVpLocations, (nvl) => {
          result.push(nvl);
        });
        orderAsc = !orderAsc;
      }

      this.items = [...result];
      this.nextStowingItem();
    },

    nextStowingItem() {
      if (this.currentPickingItemIndex == null) {
        this.currentPickingItemIndex = 0;
      } else {
        this.currentPickingItemIndex =
          this.currentPickingItemIndex == this.items.length - 1
            ? null
            : this.currentPickingItemIndex + 1;
      }

      if (this.currentPickingItemIndex != null) {
        if (this.items[this.currentPickingItemIndex].status) {
          this.nextStowingItem();
        } else {
          this.currentPickingItem = {
            ...this.items[this.currentPickingItemIndex],
          };
        }
      }
    },

    showDialogOutOfStock() {
      this.dialogOutOfStock = true;
    },
    hideDialogOutOfStock() {
      this.dialogOutOfStock = false;
    },
    confirmOutOfStock(quantity) {
      this.currentPickingItem = {
        ...this.currentPickingItem,
        is_out_of_stock: true,
        out_of_stock_quantity: quantity,
      };
      this.inputScan();
      this.hideDialogOutOfStock();
    },

    showDialogMissingStock() {
      this.dialogMissingStock = true;
    },
    hideDialogMissingStock() {
      this.dialogMissingStock = false;
    },
    confirmMissingStock(quantity) {
      this.currentPickingItem = {
        ...this.currentPickingItem,
        is_missing_stock: true,
        missing_quantity: quantity,
      };
      this.inputScan();
      this.hideDialogMissingStock();
    },

    async getGoodsData() {
      if (!this.currentGoodsId) {
        this.goods = {};
        return false;
      }
      const { data } = await httpClient.post("/goods/v1/detail", {
        id: this.currentGoodsId,
      });
      this.goods = { ...data };
    },

    async cancelPickupType1() {
      if (!confirm(this.$t("messages.stop_pickup_type_1"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-issue-detail/v1/pickup-cancel-type-1", {
          id_pickup_command: this.currentPickingItem.id_pickup_command,
          id_basket: this.currentPickingItem.id_basket,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.$emit("onUpdate", {
          ...this.filters,
          process_type: null,
          pickup_type: null,
          order_ids: [],
          scannedOrders: [],
          id_command: null,
        });
        this.$vToastify.success(this.$t("messages.pickup_done"));
      } catch (e) {
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async pickupLaterRackAndBff() {
      if (!confirm(this.$t("messages.skip_aisle_noti"))) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-later-rack-and-bff",
          {
            id_pickup_command: this.currentPickingItem.id_pickup_command,
            id_warehouse_location:
              this.currentPickingItem.id_warehouse_location,
          }
        );
        this.isLoading = false;
        this.currentPickingItemIndex = null;
        this.detailCommand = [...data];
        this.isLoading = false;
        this.sortLocations();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async pickupLater() {
      if (!confirm(this.$t("messages.skip_position_noti"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-later",
          {
            id_pickup_command: this.currentPickingItem.id_pickup_command,
            id_warehouse_location:
              this.currentPickingItem.id_warehouse_location,
          }
        );
        this.isLoading = false;
        this.currentPickingItemIndex = null;
        this.detailCommand = [...data];
        this.isLoading = false;
        this.sortLocations();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    this.getDetailCommand();
  },
};
</script>

<style lang="scss">
.box-quantity {
  position: relative;

  .sum-by-cell {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
