<template>
  <v-row>
    <v-col cols="12" class="font-weight-medium">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center primary--text">
                {{ orderData.e_marketplace_name }}
              </th>
              <th role="columnheader" class="text-center error--text">
                {{ $t("labels.customer_1") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.basket_code") }}
              </th>
              <th role="columnheader" class="text-center error--text">
                {{ $t("labels.employee_pickup") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time_pickup") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time_create") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <td class="primary--text">
                {{ orderData.customer_e_marketplace_shop_name }}
              </td>
              <td class="error--text">{{ orderData.company_name }}</td>
              <td>{{ basket.code }}</td>
              <td class="error--text">{{ orderData.employee_name }}</td>
              <td>{{ formatDateTime(orderData.pickup_at) }}</td>
              <td class="error--text">
                {{ formatDateTime(orderData.create_timestamp) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
        v-if="isMissing"
        class="error--text text-h6 text-decoration-underline"
      >
        {{ $t("messages.missing_basket") }}
      </div>
    </v-col>
    <v-col cols="8">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.image") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="max-width: 300px"
              >
                {{ $t("labels.goods") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.request_quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.scanned") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.note") }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="lastScanItem" class="text-center">
              <td>
                <ImageViewer
                  v-if="lastScanItem.url_images"
                  :url="lastScanItem.url_images"
                />
              </td>
              <td style="max-width: 300px">
                <div>{{ lastScanItem.name }}</div>
              </td>
              <td>
                {{ lastScanItem.sku }} <br />
                <v-btn
                  x-small
                  color="success"
                  @click="showDialogGoodsLocation(lastScanItem.sku)"
                >
                  {{ $t("labels.position") }}
                </v-btn>
              </td>
              <td>{{ lastScanItem.customer_goods_barcode }}</td>
              <td class="text-h4">{{ lastScanItem.quantity }}</td>
              <td
                class="text-h4"
                :class="{
                  'error--text':
                    lastScanItem.quantity != scannedQuantity(lastScanItem),
                }"
              >
                {{ scannedQuantity(lastScanItem) }}
              </td>
              <td
                class="font-italic"
                :style="lastScanItem.comment_packing ? 'width: 100px' : ''"
              >
                {{ lastScanItem.comment_packing || "" }}
              </td>
              <td>
                <div
                  class="pa-1"
                  v-if="
                    !lastScanItem.uid_scan_require &&
                    lastScanItem.pack_show_qr_code
                  "
                >
                  <qrcode-vue
                    :value="`${lastScanItem.sku}`"
                    :size="60"
                    level="H"
                  ></qrcode-vue>
                </div>
              </td>
            </tr>
            <tr
              v-for="(item, index) in otherItems"
              :key="`goods_${index}_${item.id_goods}`"
              class="text-center"
            >
              <td>
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td style="max-width: 300px">
                <div>{{ item.name }}</div>
              </td>
              <td>
                {{ item.sku }} <br />
                <v-btn
                  x-small
                  color="success"
                  @click="showDialogGoodsLocation(item.sku)"
                >
                  {{ $t("labels.position") }}
                </v-btn>
              </td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td class="text-h4">{{ item.quantity }}</td>
              <td
                class="text-h4"
                :class="{
                  'error--text': item.quantity != scannedQuantity(item),
                }"
              >
                {{ scannedQuantity(item) }}
              </td>
              <td
                class="font-italic"
                :style="item.comment_packing ? 'width: 100px' : ''"
              >
                {{ item.comment_packing || "" }}
              </td>
              <td>
                <div
                  class="pa-1"
                  v-if="
                    !item.uid_scan_require &&
                    item.pack_show_qr_code &&
                    item.quantity != scannedQuantity(item)
                  "
                >
                  <qrcode-vue
                    :value="`${item.sku}`"
                    :size="60"
                    level="L"
                  ></qrcode-vue>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="4">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.packaging") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in packaging"
              :key="`bb_${item.id}_${key}`"
              class="text-center"
            >
              <td class="text-h4">{{ item.barcode }}</td>
              <td class="text-h4">{{ item.quantity }}</td>
              <td>
                <v-btn small color="error" @click="removePackaging(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-dialog v-model="goodsLocationDialog" max-width="400px">
      <GoodsLocationDialog
        v-if="goodsLocationDialog"
        :id-warehouse="order.id_warehouse"
        :sku="locationSku"
        @cancel="hideDialogGoodsLocation"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";
import QrcodeVue from "qrcode.vue";
export default {
  name: "PackingItems",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    GoodsLocationDialog: () => import("@/components/goods/GoodsLocationDialog"),
    QrcodeVue,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    basket: {
      type: Object,
      default: () => {},
    },
    command: {
      type: Object,
      default: () => {},
    },
    scannedItems: {
      type: Array,
      default: () => [],
    },
    packaging: {
      type: Array,
      default: () => [],
    },
    lastIdGoods: {
      type: [Number, String],
      default: () => null,
    },
    isMissing: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    goods: [],
    orderData: {},
    locationSku: null,
    goodsLocationDialog: false,
  }),
  computed: {
    lastScanItem() {
      if (!this.goods || this.goods.length === 0 || !this.lastIdGoods) {
        return null;
      }
      return [...this.goods].find((g) => g.id_goods == this.lastIdGoods);
    },
    otherItems() {
      if (!this.goods || this.goods.length === 0) {
        return [];
      }
      if (!this.lastIdGoods) {
        return [...this.goods];
      }
      return [...this.goods].filter((g) => g.id_goods != this.lastIdGoods);
    },
  },
  mounted() {
    this.getGoodsByOrder();
  },
  methods: {
    formatDateTime,
    showDialogGoodsLocation(sku) {
      this.locationSku = sku;
      this.goodsLocationDialog = true;
    },
    hideDialogGoodsLocation() {
      this.locationSku = null;
      this.goodsLocationDialog = false;
    },
    async getGoodsByOrder() {
      const { data } = await httpClient.post(
        "/goods-issue-detail/v1/packing-get-items-by-order",
        {
          id: this.order.id,
        }
      );
      this.orderData = { ...data.order };
      const goods = [];
      data.goods.forEach((io) => {
        const checkio = goods.findIndex((io3) => io3.id_goods == io.id_goods);
        io.quantity = +io.quantity;
        if (checkio < 0) {
          goods.push(io);
        } else {
          goods[checkio].quantity += io.quantity;
        }
      });
      this.goods = [...goods];
    },
    scannedQuantity(item) {
      const sItem = [...this.scannedItems].find(
        (s) => s.id_goods == item.id_goods
      );
      if (!sItem) {
        return 0;
      }
      return sItem.quantity;
    },
    removePackaging(id) {
      this.$emit("removePackaging", id);
    },
  },
};
</script>

<style scoped></style>
