<template>
  <v-card>
    <v-card-title class="text-h6 mb-5">
      {{ $t("labels.missing_quantity_pickup") }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="cell_id"
            class="c-input-small"
            :label="$t('labels.missing_quantity_pickup_position')"
            dense
            outlined
            clearable
            hide-details
            ref="inputIndex_1"
            @keyup.enter="nextInputFocus(1)"
            append-icon="mdi-qrcode-scan"
            @click:append="showQRCodeScan('cell_id')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="quantity"
            type="number"
            class="c-input-small"
            :label="$t('labels.picked_quantity')"
            dense
            outlined
            clearable
            hide-details
            ref="inputIndex_2"
            @keyup.enter="nextInputFocus(2)"
            append-icon="mdi-qrcode-scan"
            @click:append="showQRCodeScan('quantity')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="barcode"
            class="c-input-small"
            :label="$t('labels.qr_sku_sub_barcode')"
            dense
            outlined
            clearable
            hide-details
            ref="inputIndex_3"
            @keyup.enter="confirm"
            append-icon="mdi-qrcode-scan"
            @click:append="showQRCodeScan('barcode')"
            :disabled="!!goods.is_allow_pick_by_location"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabled"
      >
        {{ $t("labels.missing_reported") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "PickupMissingStock",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    goods: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cell_id: null,
    quantity: null,
    barcode: null,
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    itemQuantity() {
      return this.item.basket_cell_quantity - this.item.basket_cell_picked;
    },
    isDisabled() {
      if (!this.cell_id) {
        return true;
      }
      if (this.cell_id !== this.item.cell_id) {
        return true;
      }
      if ((!this.quantity && this.quantity !== 0) || this.quantity < 0) {
        return true;
      }
      if (this.quantity >= this.itemQuantity) {
        return true;
      }
      let inputCode = this.barcode || "";
      if (inputCode.includes("@")) {
        const inputCodeSpl = inputCode.split("@");
        inputCode = inputCodeSpl[0];
      }
      inputCode = inputCode.toUpperCase();
      if (
        this.quantity > 0 &&
        (!this.barcode ||
          ![`${this.item.goods_sku}`, this.item.goods_barcode].includes(
            inputCode
          ))
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.goods.is_allow_pick_by_location) {
      this.barcode = `${this.goods.sku}`;
    }
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      switch (filter.name) {
        case "cell_id":
          this.nextInputFocus(1);
          break;
        case "quantity":
          this.nextInputFocus(2);
          break;
        case "barcode":
          this.confirm();
          break;
      }
    },
    nextInputFocus(index) {
      if (index > 3) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.isDisabled) {
        document.getElementById("error_sound_player").play();
        return false;
      }
      const missing_quantity = this.itemQuantity - this.quantity;
      this.$emit("confirm", missing_quantity);
    },
  },
};
</script>

<style scoped></style>
